import MPRestService from './MPRestService';

class ConsultingActivityService extends MPRestService {
  endpoint;

  constructor() {
    super();
    this.endpoint = '/consulting-activity';
  }

  /**
   *
   * @param {*} consultingActivity
   */
  async newConsultingActivity(consultingActivity, categoryID) {
    const data = {
      consultingActivity,
      categoryID
    };

    try {
      return await this._post(this.endpoint, data);
    } catch (e) {
      throw e;
    }
  }

  async deleteConsultingActivity(consultingActivity) {
    try {
      return await this._delete(this.endpoint, { uid: consultingActivity });
    } catch (e) {
      throw e;
    }
  }

  async editConsultingActivity(updateData) {
    try {
      return await this._patch(this.endpoint + '/' + updateData.uid, updateData);
    } catch (e) {
      throw e;
    }
  }
}

export default new ConsultingActivityService();
