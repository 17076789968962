import {useEffect, useState} from 'react';
import ConsultingActivityService from '../../services/ConsultingActivityService';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select} from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {StyleSheet, Text} from 'react-native';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import {useTheme} from '@mui/material/styles';

export default function ConsultingActivitiesEditModal({errorMessage, setErrorMessage, row, open, onClose, consultingSessionCategories, activitiesReload}) {
  const theme = useTheme();
  const styles = StyleSheet.create({

    consultingCategory: {
      marginTop: 8,
    },
    errorMessage: {
      color: theme.palette.red,
      fontSize: 12,
    },
    btngroup: {
      justifyContent: 'center',
      paddingBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
  });
  const [editedActivityName, setEditedActivityName] = useState('');
  const [selectedConsultingSessionCategory, setSelectedConsultingSessionCategory] = useState('');
  useEffect(() => {
    if (row) {
      setEditedActivityName(row.name || '');
      const category = consultingSessionCategories.find(cat => cat.name === row.categoryName);
      setSelectedConsultingSessionCategory(category ? category.uid : '');
    } else {
      setEditedActivityName('');
      setSelectedConsultingSessionCategory('');
    }
  }, [row, consultingSessionCategories]);

  const handleEdit = async () => {
    try {
      const updateData = {
        uid: row.uid,
        name: editedActivityName.trim(),
        categoryID: selectedConsultingSessionCategory,
      };

      const res = await ConsultingActivityService.editConsultingActivity(updateData);

      if (res.status === '200') {
        onClose();
        activitiesReload();
      }
      if (res.error_code === 400) {
        setErrorMessage(res.error_message);

      }

    } catch (error) {
      console.error(error);
      setErrorMessage(error.response.error_message);

    }
  };

  const handleInputChange = (event) => {
    if (event.target.value.length >= 30) {
      setErrorMessage('Tätigkeit darf nicht mehr als 30 Zeichen haben');
    } else {
      setEditedActivityName(event.target.value);
      setErrorMessage(null);
    }
  };

  return (
      <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Datensatz bearbeiten:</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Neue Tätigkeitsbeschreibung:
          </DialogContentText>
          <TextField
              required
              margin="dense"
              id="activitiy"
              label="Beratungstätigkeit"
              type="text"
              fullWidth
              variant="standard"
              value={editedActivityName}
              onChange={handleInputChange}
              inputProps={{maxLength: 30}}
              error={errorMessage !== null}
          />

          <FormControl variant="standard" fullWidth style={styles.consultingCategory} required>
            <InputLabel id="consulting-category-activity-label">Beratungskategorie</InputLabel>
            <Select
                labelId="consulting-category-activity-label"
                id="consulting-category-activity"
                label="consulting-category"
                onChange={(event) => {
                  setSelectedConsultingSessionCategory(event.target.value);
                }}
                value={selectedConsultingSessionCategory}
                required
            >
              {Array.isArray(consultingSessionCategories) && consultingSessionCategories.map(({uid, name}) => {
                return <MenuItem value={uid} key={uid}> {name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <Text style={errorMessage !== null ? styles.errorMessage : {display: 'none'}}>
            {errorMessage}
          </Text>
        </DialogContent>
        <DialogActions style={styles.btngroup}>
          <Button variant="contained" color="primary" onClick={onClose} startIcon={<CancelIcon/>}>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleEdit} startIcon={<SaveIcon/>}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

  );

}