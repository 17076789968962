import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import {StyleSheet} from 'react-native';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = StyleSheet.create({
  btngroup:{
    justifyContent: 'center',
    paddingBottom: 16
  }
});

function DeleteModal({
  setMoreOptionsOpen,
  setDeleteDialogOpen,
  deleteDialogOpen,
  handleConfirmDelete,
  setMemberName,
}) {
  return (
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Beratungsstunde löschen?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Soll die Beratungsstunde gelöscht werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={styles.btngroup}>
        <Button variant="contained" color="primary" onClick={handleConfirmDelete} startIcon={<DeleteIcon/>}>
          Löschen
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDeleteDialogOpen(false);
            setMoreOptionsOpen(true);
          }} startIcon={<CancelIcon/>}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteModal;
