import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { useGlobalContext } from '../../contexts/globalContext';
import SCREEN from '../../utils/constants/SCREEN';
import EsteburgLogo from '../Common/EsteburgLogo';

const styles = StyleSheet.create({
  container: {
    minHeight: 60,
    flexGrow: 1,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  logo: {
    marginLeft: 'auto',
  },
  tabBarLabel: {
    fontSize: 22,
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
  memberName: {
    fontSize: 14,
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
});

export default function MPAppBar(props) {
  const { setActiveTab, setShowMemberContactInfo} = useGlobalContext();
  const { tabBarLabel } = props.options;
  const navigation = useNavigation();

  const handlePress = () => {
    setActiveTab(0);
    setShowMemberContactInfo(false);
    // @ts-ignore
    navigation.navigate(SCREEN.HOME, {});
  };

  return (
    <View style={styles.container}>
      <AppBar position="static" sx={{ background: '#E5E5E5FF', height: '100%', boxShadow: 'none' }}>
        <Toolbar style={styles.toolbar}>
          <TouchableOpacity onPress={handlePress}>
            <EsteburgLogo width={90} height={55} />
          </TouchableOpacity>
          <View>
            <Text style={styles.tabBarLabel}>{tabBarLabel}</Text>
          </View>
        </Toolbar>
      </AppBar>
    </View>
  );
}
