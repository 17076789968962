import React from 'react'; // Don't forget to import React
import { Image } from 'react-native';

import Beratungsstunden from '../../../assets/icons/beratungsstunden.svg'; // Change the import path accordingly

const BeratungsstundenIcon = ({ width, height }) => {
  const _w = width || 30;
  const _h = height || 30;

  const filterStyle = {
    filter:
      'invert(100%) sepia(0%) saturate(6394%) hue-rotate(191deg) brightness(80%) contrast(80%)',
  };

  return <Image style={{ width: _w, height: _h, ...filterStyle }} source={Beratungsstunden} />;
};

export default BeratungsstundenIcon;
