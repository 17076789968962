import MailIcon from '@mui/icons-material/Mail';
import MdPhone from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import {Box, Chip} from '@mui/material';
import {Linking, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const styles = StyleSheet.create({
  firstContainer: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: 0.5,
  },
  address: {
    flexDirection: 'row',
    padding: 0.5,
    paddingInline: 0,
    marginBottom: 0.5,
    width: '100%',
  },
  areas: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contact: {
    padding: 0.5,
    paddingInline: 0,
  },
  locationItem: {
    color: '#616161',
    marginRight: 1,
  },
  phoneContainer: {
    marginBottom: 1,
  },
  default: {
    marginTop: 10,
    marginBottom: 10
  }
});

const ConsultingSessionMemberContactInfo = ({memberContactInfo}) => {
  let year = new Date().getFullYear();
  let {address, emails, memberID, name, phones, areas, cultureNames, totalArea} = memberContactInfo;

  let email = emails && emails.length > 0 ? emails[0] : 'nicht hinterlegt';
  let phone = phones && phones.length > 0 ? phones[0] : 'nicht hinterlegt';

  function onClickMobileNumber(number) {
    let phoneNumber = '';
    if (Platform.OS === 'android') {
      phoneNumber = `tel:${number}`;
    } else {
      phoneNumber = `telprompt:${number}`;
    }
    Linking.openURL(phoneNumber);
  }

  return (
      <Box
          sx={{
            backgroundColor: '#e5e5e5',
            color: '#000',
            padding: '1rem',
            margin: '0.5rem',
            boxShadow: '0px 20px 15px -1px rgba(0,0,0,0.1)',
            zIndex: 1,
            marginBottom: '0px',
          }}
      >
        <View style={styles.firstContainer}>
          <View style={styles.header}>
            <Text style={{fontSize: 18, fontWeight: '600'}}>
              {`${memberContactInfo.name} [${memberContactInfo.memberID}]`}
            </Text>
          </View>
        </View>
        {!Object.values(address).every(value => value === '' || value === null) && (
            <View style={styles.address}>
              <PlaceIcon style={styles.locationItem}/>
              <View>
                <Text>{address.street ?? address.poBox}</Text>
                <Text>{`${address.zip} ${address.city}`}</Text>
              </View>
            </View>
        )}
        <View style={styles.areas}>
          {areas.length > 0 ? (
              <>
                <View style={styles.default}>
                  <Text><strong>{`Flächen`}</strong></Text>
                  {areas.map((area, index) => (
                      <View key={index} style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text>{cultureNames[index]}</Text>
                        <Text>{`${area} ha`}</Text>
                      </View>
                  ))}
                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text><strong>{`Gesamtfläche`}</strong></Text>
                    <Text><strong>{`${Math.round(totalArea * 100) / 100} ha`}</strong></Text>
                  </View>
                </View>

              </>
          ) : (
              <View style={styles.default}>
                <Text>Flächen: Keine Einträge vorhanden.</Text>
              </View>

          )}
        </View>

        <View style={styles.contact}>
          <TouchableOpacity onPress={() => onClickMobileNumber(phone)}>
            <Chip icon={<MdPhone/>} label={phone} style={styles.phoneContainer}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => Linking.openURL(`mailto:${email}`)}>
            <Chip icon={<MailIcon/>} label={email}/>
          </TouchableOpacity>
        </View>
      </Box>
  );
};

export default ConsultingSessionMemberContactInfo;
