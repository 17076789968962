import { Image } from 'react-native';

import Icon from '../../../assets/images/ovr-esteburg-logo.svg';

/**
 * Generate Esteburg logo component with specified width and height.
 *
 * @param {{width: number, height: number}} props - The height and width of the logo.
 * @return {JSX.Element} The Esteburg logo component.
 */
const EsteburgLogo = ({ width, height }) => {
  const _w = width;
  const _h = height;

  return <Image style={{ width: _w, height: _h, resizeMode: 'contain' }} source={Icon} />;
};

export default EsteburgLogo;
