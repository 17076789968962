import { Button } from '@mui/material';
import React from 'react';
import { TouchableOpacity, View, StyleSheet, Image, Text } from 'react-native';
import {useTheme} from '@mui/material/styles';


const HomeScreenButton = ({ imageSource, text, onPress }) => {
  const theme = useTheme();
  const styles = StyleSheet.create({
    container: {
      width: 340,
      height: 100,
      alignSelf: 'center',
      color: theme.palette.white,
      backgroundColor: theme.palette.green,
      borderRadius: 0,
      position: 'relative',
      justifyContent: 'center',
    },
    iconContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: theme.palette.white,
      fontSize: 16,
      paddingTop: 10,
      textTransform: 'none',
    },
  });

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress} style={{ pointerEvents: 'box-only' }}>
        <View style={styles.iconContainer}>
          {imageSource && <Image source={imageSource} style={{ width: 45, height: 45 }} />}
          <Text style={styles.text}>{text}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default HomeScreenButton;
