import AgricultureIcon from '@mui/icons-material/Agriculture';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ForestIcon from '@mui/icons-material/Forest';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useGlobalContext } from '../../contexts/globalContext';
import H from '../../helper/helper';


const ConsultingSessionListItem = ({
  item,
  member,
  memberPersonalDataLoading,
  activity,
  culture,
  comment,
  consultantMemberLoginID,
  cMEMail,
  handleMoreOptionsMenu,
}) => {

  const theme = useTheme();
  const styles = StyleSheet.create({
    item: {
      backgroundColor: 'transparent',
      marginHorizontal: 10,
    },
    textHeaderFirstChild: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    textBody: {
      // @ts-ignore
      color: theme.palette.black,
      fontSize: 16,
      fontWeight: 'normal',
      marginHorizontal: 15,
    },
    firstRow: {
      backgroundColor: 'transparent',
      flexDirection: 'column',
      lineHeight: 20,
    },
    row: {
      backgroundColor: 'transparent',
      flexDirection: 'row',
      lineHeight: 20,
      marginLeft: 8,
      paddingBottom: 5,
    },
    rowLast: {
      backgroundColor: 'transparent',
      width: '100%',
    },

    subheadline: {
      fontSize: 14,
    },
    moreIconContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
    },

    rowContainer: {
      backgroundColor: '#D5F5E3',
      flexDirection: 'row',
      marginHorizontal: 10,
    },
  });
  const { auth } = useGlobalContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  // @ts-ignore
  const onLongPress = (event) => {
    const { clientX, clientY } = event?.targetTouches?.[0];
    handleMoreOptionsMenu({ clientX, clientY }, item);
  };

  // @ts-ignore
  const onClick = (event) => {
    setIsExpanded(!isExpanded);
  };

  const toggleExpand = (event) => {
    event.stopPropagation(); // prevent propagation up to onLongPress, which would stop the toggleExpand logic
    setIsExpanded(!isExpanded);
  };

  const colors = {
    green: ['#d9f7e8', '#c1f0d0'],
    red: ['#f9dddd', '#f2c9c9'],
  };

  const setBackgroundColor = () => {
    return consultantMemberLoginID?.toString() === auth.uid.toString() ? colors.green : colors.red;
  };

  const isExternal = consultantMemberLoginID?.toString() !== auth.uid.toString();

  const iconColor = {
    // @ts-ignore
    green: theme.palette.green,
    red: '#e79696',
  };

  const setIconColor = () => {
    return consultantMemberLoginID?.toString() === auth.uid.toString()
      ? iconColor.green
      : iconColor.red;
  };
  const setBoxShadow = () => {
    return consultantMemberLoginID?.toString() === auth.uid.toString()
      ? '5px 5px 15px rgb(71 210 133 /40%)'
      : '5px 5px 15px rgb(231 150 150/40%)';
  };

  let memberDisplayName = '';
  if (member?.firstname || member?.lastname) {
    memberDisplayName = `${member.firstname}`
    memberDisplayName += member?.lastname ? ' ' + member?.lastname : '';
  } else {
    // company name
    if (member?.addition3) {
      memberDisplayName = `${member.addition3}`
      memberDisplayName += member?.addition2 ? ' ' + member?.addition2 : '';
      memberDisplayName += member?.addition1 ? ' ' + member?.addition1 : '';

    } else if (member?.addition2) {
      memberDisplayName = `${member.addition2}`
      memberDisplayName += member?.addition1 ? ' ' + member?.addition1 : '';
    } else if (member?.addition1) {
      memberDisplayName = `${member.addition1}`
    }
  }

  let memberDisplayNumber = item.memberID ?? '';

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  return (
    <Box
      sx={{
        marginTop: 2,
        borderRadius: 2,
        background: `linear-gradient(to right, ${setBackgroundColor()})`,
        marginX: 2,
        boxShadow: `${setBoxShadow()}`,
        padding: '10px',
      }}>
      <View style={styles.item}>
        <View style={styles.firstRow}>
          {memberPersonalDataLoading ? (
            <>
              <Skeleton variant="text" sx={{ width: 200 }} />
            </>
          ) : (
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
                paddingBottom: isExternal ? 0 : 7,
              }}>{`${memberDisplayName} [${memberDisplayNumber}]`}</Text>
          )}
          {memberPersonalDataLoading ? (
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          ) : (
            isExternal && (
              <Text style={styles.subheadline}>{`Stunden erfasst durch: ${cMEMail}`}</Text>
            )
          )}
        </View>
        <View style={styles.moreIconContainer}>
          {!isExternal && <MoreVertIcon onClick={(event) => handleMoreOptionsMenu(event, item)} />}
        </View>
        <View style={styles.row}>
          {memberPersonalDataLoading ? (
            <Skeleton variant="circular" sx={{ width: 25, height: 25 }} />
          ) : (
            <AgricultureIcon sx={{ color: setIconColor() }} />
          )}

          <Text style={styles.textHeaderFirstChild} />
          <Text style={styles.textBody}>
            {memberPersonalDataLoading ? (
              <Skeleton variant="text" sx={{ width: 100 }} />
            ) : (
              activity?.name ?? '--'
            )}
          </Text>
        </View>
        <View style={styles.row}>
          {memberPersonalDataLoading ? (
            <Skeleton variant="circular" sx={{ width: 25, height: 25 }} />
          ) : (
            <EventNoteIcon sx={{ color: setIconColor() }} />
          )}
          <Text style={styles.textHeaderFirstChild} />
          <Text style={styles.textBody}>
            {memberPersonalDataLoading ? (
              <Skeleton variant="text" sx={{ width: 200 }} />
            ) : (
              `${H.formatDateString(item?.startDate)} - ${item.hours && item.hours != 0 ? `${item.hours}h ` : ''
              }${item.minutes && item.minutes != 0 ? `${item.minutes}min` : ''}`
            )}
          </Text>
        </View>

        <View style={styles.row}>
          {memberPersonalDataLoading ? (
            <Skeleton variant="circular" sx={{ width: 25, height: 25 }} />
          ) : (
            <ForestIcon sx={{ color: setIconColor() }} />
          )}

          <Text style={styles.textHeaderFirstChild} />
          <Text style={styles.textBody}>
            {memberPersonalDataLoading ? (
              <Skeleton variant="text" sx={{ width: 100 }} />
            ) : (
              culture?.name ?? '--'
            )}
          </Text>
        </View>

        <View style={styles.rowLast}>
          {!memberPersonalDataLoading && comment?.trim() !== '' && (
            <>
              <TouchableOpacity onPress={toggleExpand}>
                <Text>
                  {isExpanded ? (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: '400',
                        margin: 8,
                        padding: 10,
                        minHeight: 50,
                        color: '#000000',
                        borderRadius: 5,
                        // @ts-ignore
                        backgroundColor: theme.palette.white,
                        border: `1px solid ${setIconColor()}`,
                      }}>
                      {comment}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: '600',
                        margin: 8,
                        padding: 7,
                        // @ts-ignore
                        color: theme.palette.white,
                        borderRadius: 5,
                        backgroundColor: `${setIconColor()}`,
                      }}>
                      Kommentar anzeigen
                    </div>
                  )}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
        <View style={styles.rowLast}>
          {item?.images?.length > 0 && (
            <>
              <TouchableOpacity onPress={handleDialogOpen}>
                <Text>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: '600',
                      margin: 8,
                      padding: 7,
                      // @ts-ignore
                      color: theme.palette.white,
                      borderRadius: 5,
                      backgroundColor: `${setIconColor()}`,
                    }}>
                    Bilder anzeigen
                  </div>
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>

      {/* Dialog to display images */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="lg">
        <DialogTitle>Bilder anzeigen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {item.images?.map((image) => (
              <Grid item xs={6} md={4} key={image.uid}>
                <Card>
                  <CardMedia
                    component="img"
                    height="150"
                    src={image.fileDataURI}
                    alt={image.fileName}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default ConsultingSessionListItem;
